import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { UserQuery } from './user/user.query';
import Amplify, { Auth } from 'aws-amplify';
import {GenesystokenService} from './genesystoken.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(public user: UserQuery,
        private router: Router,public genesysService:GenesystokenService) { 
          
        }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        var userToken='';
        if(this.genesysService.tokenType=='genesys'){
            userToken =this.genesysService.dataToken;
        }
        else{
            userToken =this.user.getToken();
            console.log(userToken)
        }
       // const userToken = this.user.getToken();
       
        const modifiedReq = req.clone({
            headers: req.headers.set('Authorization', `${userToken}`).set("tokenType",this.genesysService.tokenType),
        });

        return next.handle(modifiedReq).pipe(

            catchError((err: any) => {
                console.log(err);
                if (err instanceof HttpErrorResponse) {
                    console.log(err.error.message);
                    if (err.status === 401) {
                        this.user.setToken(null);
                        this.router.navigate(['/genesys']);
                    }

                    if (err.status === 500) {
                        Auth.currentAuthenticatedUser().catch(res => {
                            console.log(res);
                            this.user.setToken(null);
                            // this.router.navigate(['/genesys']);
                        });
                    }
                }
                throw new Error(err.error.message);
            })
        );

    }

    async refreshToken(user1:any){
        const session = await Auth.currentSession();
        //const user = Auth.currentAuthenticatedUser();
        session.getRefreshToken()
       
        const congnitoRefreshToken=session.getRefreshToken();
        console.log(congnitoRefreshToken)
        console.log(session.getIdToken().getJwtToken())
        console.log(congnitoRefreshToken.getToken())
        var token=congnitoRefreshToken.getToken()
        this.user.setToken(session.getIdToken().getJwtToken());
        console.log(user1)
        // window.location.reload();
      //  return await user.refreshSeassion(token).then((d:any)=>{
      //   console.log(d);
      //   this.user.setToken(token);
      //   //localStorage.setItem('profileToken',JSON.stringify(token));
      //  }) 
      }
}