import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Amplify, { Auth } from 'aws-amplify';
import { UserQuery } from '../services/user/user.query';

@Component({
  selector: 'app-forgot-account',
  templateUrl: './forgot-account.component.html',
  styleUrls: ['./forgot-account.component.scss']
})
export class ForgotAccountComponent implements OnInit {

  recoverForm: FormGroup;
  region:any;
  forbidden = true;
  constructor(private router: Router,private user: UserQuery) {
    this.recoverForm = new FormGroup({
      email: new FormControl('', [Validators.required, this.commaSepEmail]),
      selectedRegion:new FormControl('', [Validators.required]),
    })
  }
  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(',').map(e => e.trim());
    const patternValidator = Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i);
    this.forbidden = emails.some(email => patternValidator(new FormControl(email)));
    console.log(this.forbidden);
    return this.forbidden ? { email: { value: control.value } } : null;
  };
  ngOnInit(): void {
  //   this.region='eu-west-2'
  //   this.user.setRegion(this.region);
  //   this.recoverForm.controls.selectedRegion.patchValue(this.region)
  //  var awsconfig=this.region=="eu-west-2"?awsConfigRegion1:awsConfigRegion2 
  // Amplify.configure(awsconfig);
  }
  recover(event) {
    event.preventDefault();

    if (this.recoverForm.invalid) {
      this.recoverForm.controls.email.markAsTouched();
      this.recoverForm.controls.selectedRegion.markAsTouched();
      return;
    }
    const params = this.recoverForm.value;

    localStorage.setItem("email", params.email)
    Auth.forgotPassword(params.email.toString()).then(res => {
      this.router.navigateByUrl('/recover/verify?user=' + params.email.toString());
    }, err => {

    });
  }

}
