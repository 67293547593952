<div style="background-color: #f2eef8;height: 70vw;">
    <div class="create-sprint-container animate fade-in">
            <div class="create-sprint-form">
                <div class="title">
                    <span >
                    Hi 
                    </span>
                    <span id="username">{{username}}</span>!
                    <div>
                        Welcome to the QuickTest Application
                    </div>
                </div>
                <div style="border: 1px solid;    border-color: #e9e5e5; margin: 67px; background-color: white;">
                <div class="stepper">
                    <div class="step" *ngFor="let item of steps;let i = index" [class.active]="activeStep === i">
                        <div class="count">{{ i+1 }}</div>
                        <div class="label">{{ item.name }}</div>
                    </div>
                </div>
                <div class="active-step">
                    <div class="animate fade-in no-delay"
                        *ngIf="steps[activeStep].id == stepTypes.START">
                        <div id="available" class="sprint-name" *ngIf="productAvailable">
                            <p class="success text-center">
                                <span class="txt-product-available">Product Available </span> <i class="fas fa-check-circle enlarge"></i>
                            </p>
                            <!-- <div class="reminders">
                                 <p class="txt-available-message">Reminders:</p>
                                <ul class="reminder-list">
                                    <li>Remove the Unistall button in your application</li>
                                    <li>You may redirect to your application by updating the premiumAppURL in the /wizard/config/config.js file</li>
                                </ul>
                            </div> -->
                            
                            <!-- <button id="next" class="btn-info right">
                                <span class="txt-start">Start</span> <i class="button-icon fas fa-chevron-right"></i></button> -->
                        </div>
            
                        <!-- <div id="unavailable" class="sprint-name" *ngIf="!productAvailable">
                            <p class="fail">
                                <span class="txt-product-not-available">Product not avaiable.</span>  <i class="fas fa-times-circle enlarge"></i>
                            </p>
                            <p>
                                <span class="txt-not-available-message">
                                We're sorry but your Genesys Cloud org does not have the Premium App Sample Product enabled.
                                Please contact the authorities.
                                </span>
                            </p>
                        </div> -->
                    </div>

                    <!-- <div class="select-env animate fade-in no-delay" *ngIf="steps[activeStep].id == stepTypes.CUSTOMSETUP">
                        <div  class="sprint-name">
                            <p>
                                Put your custom provisioning here.
                                <br><br>
                                Update the ./docs/wizard/scripts/main.js to call your custom provisioning functions.
                            </p>
                            <button id="next" class="btn-info right">
                                <span class="txt-start">Start</span> <i class="button-icon fas fa-chevron-right"></i>
                            </button>
                        </div>
                    </div> -->


                    <div class="select-env animate fade-in no-delay" *ngIf="steps[activeStep].id == stepTypes.INSTALL">
                        <p class="sprint-name"> 
                            <span class="txt-install-summary">These are the steps that will be automatically performed for you!</span> 
                        </p>
            
                        <!-- <div class="message">
                            <div class="message-title">
                                <span class="txt-create-role">1. Create Role</span>
                            </div>
                            <div class="message-content">
                                <div>
                                    <span class="txt-create-role-msg">
                                        Create a role specifically to provide access to the app.
                                    </span>
                                </div> 
                            </div>
                        </div> -->
            
                        <!-- <div class="message">
                            <div class="message-title">
                                    <span class="txt-create-group">2. Create Groups</span>
                            </div>
                            <div class="message-content">
                                <div>
                                    <span class="txt-create-group-msg">
                                        Groups will provide filtering to users' access to the app instances.
                                    </span> 
                                </div> 
                            </div>
                        </div> -->
            
                        <div class="message">
                            <div class="message-title">
                                <span class="txt-create-instance">
                                1. Create instances
                                </span>
                            </div>
                            <div class="message-content">
                                <div>
                                    <span class="txt-create-instance-msg">
                                        More instances of the app will be created with different URI endpoints to provide different features of the app.
                                    </span>
                                </div> 
                            </div>
                        </div>
            
                        <div class="message">
                            <div class="message-title">
                                <span class="txt-create-oauthclient">
                                2. Create OAuth Client
                                </span>
                            </div>
                            <div class="message-content">
                                <div>
                                    <span class="txt-create-oauthclient-msg">
                                        An optional OAuth Client could be created for  server-to-server communication. After creation, it should be followed by a method that will pass the OAuth Client information to the app's server.
                                    </span>
                                </div> 
                            </div>
                        </div>
            
                        <p class="sink-more sprint-name">
                            <span class="txt-please-click">
                                Please click the button to start the installation.
                            </span>
                        </p>
            
                        <!-- <button id="start" class="btn-info right">
                            <span class="txt-start">Start</span> <i class="button-icon fas fa-chevron-right"></i>
                        </button> -->
                    </div>
                    <!-- <div class="select-env animate fade-in no-delay" *ngIf="steps[activeStep].id == stepTypes.SUMMARY">
                        <div class="success sprint-name">
                            <div class="txt-finish-install">
                                Please wait while we take you to the application. 
                            </div>
                            <div id="loading" style="margin-top: 30px; text-align: center;" *ngIf="IsSetupComplete">
                                <i class="fa fa-spinner fa-spin" style="font-size:70px; color: #1997CA;"></i>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="btn-set">
                    <!-- <div *ngIf="!productAvailable"> -->
                    <app-button class="btn" [size]="'sm'" (click)="nextStep()" *ngIf="activeStep < steps.length-1">
                        Next</app-button>
        
                        <app-button class="btn" [size]="'sm'" (click)="saveSprint()" *ngIf="activeStep === steps.length-1">
                            Save</app-button>
                    <!-- </div> -->
                    <!-- <div *ngIf="productAvailable">
                        <app-button class="btn" [size]="'sm'" (click)="saveSprint()" >
                            Save</app-button>
                    </div>
                     -->
                    
                </div>
                </div>
            </div>
    </div>
</div>