import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { UserQuery } from './services/user/user.query';
import {requestsService} from './services/requests/requests.service';
import { CookieService } from "ngx-cookie";
import { of } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{
  title = 'QuickTest';
  userData:any
  constructor(private router: Router,
    private user: UserQuery,private apiService: requestsService, private userQuery: UserQuery,private cookie:CookieService ,) {
  }


  ngOnInit(): void {
    
  //  console.log(window.location.href,window.location.hash)
  //  let url=window.location.href
  //  console.log(new URLSearchParams(url).get('id_token'))
  //  if(new URLSearchParams(url).get('id_token')!=null){
  //     if(window.location.href.search("access_token") && window.location.href.search("access_token")!=-1){ 
  //         // this.propService.EmailLink="http://localhost:4001/#/home"
  //        console.log()
  //         var token= new URLSearchParams(url).get('id_token');
  //         console.log(token)
  //         if(token){
  //             this.userQuery.setSamlUser("SamlUser")
  //         }
  //         var base64Url = token.split('.')[1];
  //         var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  //         var jsonPayload:any = decodeURIComponent(window.atob(base64).split('').map(function(c) {
  //             return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  //         }).join(''));
  //        var tokenpayload =JSON.parse(jsonPayload);
  //         console.log(tokenpayload)
  //         console.log(this.userQuery.getSamlUser())
  //         const userdate={
  //             "name":tokenpayload.name,
  //             "middle_name":tokenpayload.middle_name,
  //             "phone_number":tokenpayload.phone_number,
  //             "sub":tokenpayload.sub,
  //             "email":tokenpayload.email
  //         }
  //         this.userQuery.setUser(userdate);
  //         this.userQuery.setToken(token)
  //         this.apiService.getUserdetails().subscribe(s=>{
  //           console.log(s)
  //           this.userData=s
  //           if(this.userData.firstCorporateLogin==true){
  //             localStorage.removeItem("UserCred");
  //             this.cookie.remove("startDate")
  //             this.cookie.remove("endDate");
  //             localStorage.removeItem("tabBrowserService");
  //             localStorage.removeItem("tabBrowser")
  //             this.userQuery.logout();
  //             return of(false);
  //           }else{
  //             this.router.navigateByUrl('/home');
  //             return of(true);
  //           }
  //         });
  //     }
  //  }
   // main.setup();
  }
  
  doUnload() {
    // Clear session or do something
   // this.auth.getLogout();
}

}
