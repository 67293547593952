import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Amplify, { Auth } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { UserQuery } from '../services/user/user.query';
@Component({
  selector: 'app-reset-account-confirm',
  templateUrl: './reset-account-confirm.component.html',
  styleUrls: ['./reset-account-confirm.component.scss']
})
export class ResetAccountConfirmComponent implements OnInit {

  username = null;

  verifyUser: FormGroup;
  constructor(
    private router: Router,
    private toastr: ToastrService,private user1: UserQuery,
    private activeRouter: ActivatedRoute) {
    this.verifyUser = new FormGroup({
      code: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required,Validators.minLength(8),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')])
    });

    let user = this.activeRouter.snapshot.queryParams.user;
    user=localStorage.getItem('email')
    if (user) {
      this.username = user;
    } else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
  }

  verify(event) {
    event.preventDefault();
    if (this.verifyUser.invalid) {
      return;
    }
    const params = this.verifyUser.value;
    Auth.forgotPasswordSubmit(this.username, params.code, params.password)
      .then(res => {
        this.toastr.success('Password updated successfully.')
        this.router.navigateByUrl('/');
      }, err => {
        this.toastr.error('Try again')
      });
  }

}
