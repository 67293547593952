//export const awsconfig = {
    // Auth: {
    //     region: 'eu-west-2',
    //     userPoolId: 'eu-west-2_mDTmwXTea',
    //     userPoolWebClientId: '6cib6q3tds2sstsrd0uq6l830c',
       // mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: SETTINGS.DOMAIN,
        //     // OPTIONAL - Cookie path
        //     path: '/',
        //     // OPTIONAL - Cookie expiration in days
        //     expires: 30,
        //     // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        //     // sameSite: "none",
        //     // OPTIONAL - Cookie secure flag
        //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     // secure: true
        // },
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientsMetadata: { myCustomKey: '473geban6asfl3qhfrvb4l4liiqr7cpkl5kb34us50mak9epamq' },

        // OPTIONAL - Hosted UI configuration
        // oauth: {
        //     domain: 'https://quicktest.auth.eu-west-2.amazoncognito.com',
        //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        //     redirectSignIn: 'http://localhost:4200/home/dashboard',
        //     redirectSignOut: 'http://localhost:4200/',
        //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        // }
    //}
//};

const Auth={
    'region': 'eu-west-2',
    'userPoolId': 'eu-west-2_l6RGfZ8fS',
    'userPoolWebClientId': 'l4cdqg69524auqlckjcifr44p',
};
export default Auth
