import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { HomeModule } from './home/home.module';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { ForgotAccountComponent } from './forgot-account/forgot-account.component';
import { PaymentRedirectComponent } from './external/payment-redirect/payment-redirect.component';
import { ResetAccountConfirmComponent } from './reset-account-confirm/reset-account-confirm.component';
import {GenesysSetupComponent} from './genesys-setup/genesys-setup.component';


const routes: Routes = [
  {
    path: '',
    component: GenesysSetupComponent
  },
  {
    path: 'genesys',
    component: GenesysSetupComponent
  },
  {
    path: 'login/:resion',
    component: LoginComponent
  },
  {
    path: 'signup/:organizationId/:region',
    component: SignupComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'verify',
    component: VerifyAccountComponent
  },
  {
    path: 'recover',
    component: ForgotAccountComponent
  },
  {
    path: 'recover/verify',
    component: ResetAccountConfirmComponent
  },
  {
    path: 'external/payment',
    component: PaymentRedirectComponent
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomeModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
