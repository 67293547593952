import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Auth } from 'aws-amplify';
import { switchMap ,map, take, distinctUntilChanged} from 'rxjs/operators';
import { Observable, interval, Subscription } from 'rxjs';
import { requestsService } from '../services/requests/requests.service';
import { UserQuery } from '../services/user/user.query';
import { DomSanitizer } from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie'
import { ToastrService } from 'ngx-toastr';
import {GenesystokenService} from '../services/genesystoken.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('viewDOM') viewDOM: ElementRef;

  settingWidth:boolean=false
  user: any;
  thumbnail:any;
  availableFeatures = {
    AutomaticTesting: false,
    LoadTesting: false,
    ScheduledTesting: false
  }

  navList = [
    {
      name: 'Dashboard',
      icon: 'dashboard.svg',
      iconSelected: 'dashboard-light.svg',
      route: 'dashboard',
      children: [
        {
          name: 'Custom Dashboard',
          route: 'custom-dashboard'
        },
      ]
    },
    {
      name: 'Service',
      icon: 'service.svg',
      iconSelected: 'service-light.svg',
      route: 'service'
    },
    {
      name: 'Script',
      icon: 'script.svg',
      iconSelected: 'script-light.svg',
      route: 'script'
    },
    {
      name: 'Test Method',
      icon: 'testing.svg',
      iconSelected: 'testing-light.svg',
      route: 'test-method',
      children: [
        {
          name: 'Load Testing',
          route: 'load-testing',
          available: 'LoadTesting'
        },
        {
          name: 'Scheduled Testing',
          route: 'scheduled-testing',
          available: 'ScheduledTesting'
        },
        {
          name: 'Automated Testing',
          route: 'automated-testing',
          available: 'AutomaticTesting'
        },
        {
          name: 'Sprint Folders',
          route: 'sprint-folders'
        }
      ]
    },
   
    {
      name: 'Reports',
      icon: 'reports.svg',
      iconSelected: 'reports-light.svg',
      route: 'reports',
      children: [
        // {
        //   name: 'Summary',
        //   route: 'summary'
        // },
        {
          name: 'High Level Report',
          route: 'high-level'
        },
        {
          name: 'Sprint Details Report',
          route: 'sprint'
        },
        {
          name: 'Invoice',
          route: 'invoice'
        },
        {
          name: 'Load Sprint Report',
          route: 'loadSprint'
        },
        {
          name: 'Script Folder Report',
          route: 'script-folder'
        },
        {
          name: 'Sprint Folder Report',
          route: 'sprint-folder'
        },
        {
          name: 'Audit logs',
          route: 'audit-log'
        },
        {
          name: 'SubOrg Report',
          route: 'getSubOrgReport'
        }, 
      ]
    },
    // {
    //   name: 'Membership',
    //   icon: 'membership.svg',
    //   iconSelected: 'membership-light.svg',
    //   route: 'membership'
    // },
    {
      name: 'IVR Tree (Beta)',
      iconSelected: 'chart.svg',
      icon: 'chart-light.svg',
      activeSectionName:"Ivr",
      route: 'Ivr',
     // display:true// check 
    },
    {
      name: 'Browser Calling',
      route: 'browser',
      icon: 'browser-calling.svg',
      activeSectionName:"browser",
      iconSelected: 'browser-svgrepo-com.svg',
    
    },
    {
      name: 'Chat Bot',
      iconSelected: 'bot.png',
      icon: 'bot.png',
      activeSectionName:"chat-bot",
      route: 'chat-bot',
      children: [
        {
          name: 'GenAI Testing (Beta)',
          route: 'Bot2Bot-menu-testing'
        },
      ]
    },
    {
      name: 'Enhanced Proactive Monitoring',
      iconSelected: 'monitoring.svg',
      icon: 'monitoring-system-icon.svg',
      activeSectionName:"chat-bot",
      route: 'proactive-monitoring',
    },
    {
      name: 'Rapid Onboarding',
      // iconSelected: 'monitoring.svg',
      // icon: 'monitoring-system-icon.svg',
      activeSectionName:"chat-bot",
      route: 'rapid-onboarding',
    },
    {
      name: 'Help',
    },
  ];
  adminnavList = [
    {
      name: 'Invoice Report',
      icon: 'reports.svg',
      iconSelected: 'reports-light.svg',
      route: 'admin-pannel'
    },
    {
      name: 'Sprint Details Report',
      icon: 'reports.svg',
      iconSelected: 'reports-light.svg',
      route: 'admin-sprintDetails-report'
    },
    {
      name: 'Organization Detail',
      icon: 'reports.svg',
      iconSelected: 'reports-light.svg',
      route: 'admin-Organization-Detail'
    },
    {
      name: 'User Info',
      icon: 'service.svg',
      iconSelected: 'service-light.svg',
      route: 'admin-user-info'
    },
    {
      name: 'Notification',
      icon: 'service.svg',
      iconSelected: 'service-light.svg',
      route: 'admin-notification'
    },
  ];

  activePage = 'dashboard';
  activeSection = 'dashboard'

  showUserMenu :boolean = false;
  memberShipPlan:string='';
  platformclient:any;
  readAccess:boolean=false;
  writeAccess:boolean=false;
  fullAccess:boolean=false;
  lastloginTime:any;
  currentUrl:any;
  UserDataList:any;
  isAdmin:boolean=false;
  listNotification=[];
  allRead:boolean=false
  private userQuerySubscription: Subscription;
  
  constructor(
    private routerQuery: RouterQuery,
    private userQuery: UserQuery,
    private router: Router,
    private apiService: requestsService,
    private sanitizer: DomSanitizer,
    private cookie:CookieService,
    private toastr: ToastrService,
    private geneSysService:GenesystokenService,
    private http: HttpClient) {}

  ngOnInit(): void {
    if(this.geneSysService.tokenType=='aws'){
      
      this.userQuerySubscription = this.geneSysService.currentImage.subscribe((imageUrl) => {
       
        if (imageUrl) {
          if(imageUrl.size===0){
            this.thumbnail ="../../assets/dummy-profile-pic.jpg"
           // this.geneSysService.updateImage(this.thumbnail);
          }else{
            var unsafeImageUrl = URL.createObjectURL(imageUrl);
            this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
            //this.geneSysService.updateImage(imageUrl);
            // var unsafeImageUrl = URL.createObjectURL(res.blob());
            // this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
            if(this.geneSysService.tokenType=='genesys'){
              //this.geneSysService.updateImage(imageUrl);
            }
            else{
              //this.userQuery.setUserImg(imageUrl);
            }
             
          } 
        }
      });
      this.userQuery.alreadyLogged().subscribe(user => {
       this.user=user
       
      });

      if(this.userQuery.getSamlUser()=="SamlUser"){
        this.loadProfileImg();
      }
      this.apiService.getRoleListByUsername().subscribe(res=>{
        this.lastloginTime= this.userQuery.getLoginTime()
        this.userQuery.setRole(res.role);
        this.userQuery.getRole().subscribe((user: any) => {
          if(user){
          this.readAccess=user.readAccess;
          this.writeAccess=user.writeAccess;
          this.fullAccess=user.fullAccess
          }
        });
      })
    
        Auth.currentUserInfo().then(res => {
          if (res && res.attributes) {
            this.userQuery.setUser(res.attributes);
            this.loadProfileImg();
          } else {
           // this.logout();
          }
        }, err => {
          this.logout();
        });
    }
    else{
      this.userQuerySubscription = this.geneSysService.currentImage.subscribe((imageUrl) => {
       
        if (imageUrl) {
          if(imageUrl.size===0){
            this.thumbnail ="../../assets/dummy-profile-pic.jpg"
           // this.geneSysService.updateImage(this.thumbnail);
          }else{
            var unsafeImageUrl = URL.createObjectURL(imageUrl);
            this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
            //this.geneSysService.updateImage(imageUrl);
            // var unsafeImageUrl = URL.createObjectURL(res.blob());
            // this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
            if(this.geneSysService.tokenType=='genesys'){
              //this.geneSysService.updateImage(imageUrl);
            }
            else{
              //this.userQuery.setUserImg(imageUrl);
            }
             
          } 
        }
      });
      this.platformclient= (<any>window).platformClient;
      const usersApi = new this.platformclient.UsersApi();
      let opts = {'expand': ['authorization']};
      usersApi.getUsersMe(opts).then(res=>{
        this.user=res;
        this.geneSysService.setUser(res);
        this.loadProfileImg();
      }).catch((e) => {console.error(e);this.logout();}); 
 // return usersApi.getUsersMe(opts);
    }

    if(this.user){
      console.log(this.user)
    }
    this.routerQuery.select(routerData => routerData.state).subscribe(state => {
      window.scrollTo(0, 0);
      const path = state.url.split('/');
      var url= state.url.split('/').pop();
     this.currentUrl=url
      if (path.length > 2) {
        this.activeSection = path[2];
        if (path.length > 3) {
          this.activePage = path[3].split('?')[0];
        } else {
          this.activePage = path.reverse()[0];
        }
      }

    });
    if(this.geneSysService.tokenType=="aws"){
      this.apiService.getUserdetails().subscribe(res=>{
        this.UserDataList=res
        if(this.UserDataList.firstCorporateLogin==true){
          localStorage.removeItem("UserCred");
          this.cookie.remove("startDate")
          this.cookie.remove("endDate");
          localStorage.removeItem("tabBrowserService");
          localStorage.removeItem("tabBrowser")
          this.userQuery.logout();
        }
      if(res){
      this.apiService.userPlanDetails().subscribe(res => {
        if (res) {
          this.memberShipPlan=res.membershipName
          this.userQuery.setCurrentPlan(res);
        }
  
        if (!res || res.id === 0 || res.id === '0') {
          this.router.navigateByUrl('/home/membership');
        }
      });
      this.apiService.getRoleListByUsername().subscribe(res=>{
        this.userQuery.setRole(res.role);
        this.userQuery.getRole().subscribe((user: any) => {
          if(user){
          this.readAccess=user.readAccess;
          this.writeAccess=user.writeAccess;
          this.fullAccess=user.fullAccess;
          }
        });
      })
    }
    })
      // active listner for plan changes. So we can update the feature availability
      this.userQuery.currentPlan().pipe(
        switchMap(res => {
          return this.apiService.getTestTypeForUser();
        })
      ).subscribe((res: any) => {
        this.availableFeatures.AutomaticTesting = res.AutomaticTesting;
        this.availableFeatures.LoadTesting = res.LoadTesting;
        this.availableFeatures.ScheduledTesting = res.ScheduledTesting;
      });
    }
   else{
    this.apiService.userPlanDetails().subscribe(res => {
      console.log(res)
      if (res) {
          this.memberShipPlan=res.membershipName;
          console.log(this.memberShipPlan)
          //this.userQuery.setCurrentPlan(res);
        }
    })
    this.apiService.getRoleListByUsername().subscribe(res=>{
      console.log(res)
      this.userQuery.setRole(res.role);
      this.userQuery.getRole().subscribe((user: any) => {
        console.log(user);
        this.readAccess=user.readAccess;
        this.writeAccess=user.writeAccess;
        this.fullAccess=user.fullAccess
      });
    })
    // this.apiService.userPlanDetails().subscribe(res => {
    //   if (res) {
    //     this.memberShipPlan=res.membershipName
    //     //this.userQuery.setCurrentPlan(res);
    //   }

    //   if (!res || res.id === 0 || res.id === '0') {
    //     this.router.navigateByUrl('/home/membership');
    //   }
    // });

    this.apiService.getTestTypeForUser().subscribe((res: any) => {
      this.availableFeatures.AutomaticTesting = res.AutomaticTesting;
      this.availableFeatures.LoadTesting = res.LoadTesting;
      this.availableFeatures.ScheduledTesting = res.ScheduledTesting;
    });
   }
    // this.myBrowser();
    this.notificationList()
  }
 
  public getTokenDetailsFromCognito(callbackCode: string): Observable<any> {
    const details = {
      grant_type: 'authorization_code',
      code: callbackCode,
      scope: 'openid+profile',
      redirect_uri: "https://demoquicktest.auth.eu-west-2.amazoncognito.com/login?client_id=l4cdqg69524auqlckjcifr44p&response_type=token&scope=email+openid+profile&redirect_uri=http://localhost:4200/#/home"
    };
    const formBody = Object.keys(details)
                           .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`)
                           .join('&');
    var id="l4cdqg69524auqlckjcifr44p"
    return this.http.post<any>("https://demoquicktest.auth.eu-west-2.amazoncognito.com/oauth2/token",
      formBody, {
        responseType: 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic ' + btoa(`${id}`)
          })
        });
  }

  selectMenu(route){
    // '/home/'+route;
      window.open('#/home/'+route)
      localStorage.setItem("tabBrowserService",'/home/'+route)
  }
  selectSubMenu(route,subroute){
    window.open('#/home/'+route+'/'+subroute)
    localStorage.setItem("tabBrowserService",'/home/'+route+'/'+subroute)
  }
  selectSubMenu1(route,subroute){
    window.open('#/home/'+route+'/'+subroute)
    localStorage.setItem("tabBrowserService",'/home/'+route+'/'+subroute)
  }
  loadProfileImg() {
    this.apiService.getProfileImg().subscribe(res => {
      if (res) {
        if(res.size==0){
          this.thumbnail ="../../assets/dummy-profile-pic.jpg"
          this.userQuery.setUserImg(this.thumbnail);
        }
        else{
          var unsafeImageUrl = URL.createObjectURL(res);
          this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          // var unsafeImageUrl = URL.createObjectURL(res.blob());
          // this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          if(this.geneSysService.tokenType=='genesys'){
            this.geneSysService.updateImage(res);
          }
          else{
            this.userQuery.setUserImg(res);
          }
           
        }
        
      }
    }, err => {
      this.thumbnail ="../../assets/dummy-profile-pic.jpg"
      if(this.geneSysService.tokenType=='genesys'){
        // this.geneSysService.setUserImg(this.thumbnail);
      }
      else{
        this.userQuery.setUserImg(this.thumbnail);
      }
      console.log(err)
    });
  }

  toggleSidePanel() {
    this.viewDOM.nativeElement.classList.toggle('wide');
  }

  userMenuToggler() {
    this.showUserMenu = !this.showUserMenu;
    console.log(this.showUserMenu)
  }
  UserGuide() {
    window.open('https://cloudcx.ai/quick-test-user-guide/')
    // console.log("test")
    // document.getElementById("mySidenav1").style.width = "300px";
    // this.settingWidth=true
    // document.getElementById("menuHeaderpanel").style.width = "80%";
}
closeNav1(){
  document.getElementById("mySidenav1").style.width = "0";
  document.getElementById("menuHeaderpanel").style.width = "100%";
  //document.getElementById("allMainPage").style.width = "100%";
  //this.settingWidth="routerletwidth"
  this.settingWidth=false
}
  closeDropdown() {
    if (this.showUserMenu == true) {
      this.showUserMenu = false;
    }else{
      return;
    }
  }

  navTo(url) {
    this.userMenuToggler();
    // this.userMenuTogglerclose();
    this.router.navigateByUrl(url);
  }

  logout() {
    localStorage.removeItem("UserCred");
    this.cookie.remove("startDate")
    this.cookie.remove("endDate");
    localStorage.removeItem("tabBrowserService");
    localStorage.removeItem("tabBrowser")
    this.cookie.remove("startDate")
    this.cookie.remove("SamlUser")
    this.userQuery.logout();
  }
  closeNav(){
    this.notificationList()
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("menuHeaderpanel").style.width = "100%";
    const iframe = document.getElementById('menuHeaderpanel') as HTMLIFrameElement;
   
    //document.getElementById("allMainPage").style.width = "100%";
    //this.settingWidth="routerletwidth"
    this.settingWidth=false;
  }
  openNav(){
    this.notificationList()
    document.getElementById("mySidenav").style.width = "300px";
    this.settingWidth=true
    document.getElementById("menuHeaderpanel").style.width = "80%";
    // this.refreshIframe()
    //this.settingWidth="opennavigationrouterwidth"
    console.log(document.getElementById("mySidenav"),document.getElementById("menuHeaderpanel"))
    //\\ this.router.navigateByUrl('/show-notification');
  }
  refreshIframe() {
    const iframe = document.getElementById('notificationIframe') as HTMLIFrameElement;
    if (iframe) {
      iframe.contentWindow.location.reload();
    }
  }
  
  openTestHistory(){
    this.router.navigateByUrl('/home/settings/login-data/login-history');
  }
  notificationList(){
    this.apiService.latestNotifications().subscribe(res=>{
      this.listNotification=res.body.result
      this.allRead = this.listNotification.some(item => !item.isRead);
      console.log(this.allRead)
    })
  }
  ngOnDestroy() {
    if (this.userQuerySubscription) {
      this.userQuerySubscription.unsubscribe();
    }
  }
}
