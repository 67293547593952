import { Injectable,Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenesystokenService {
  tokenType:string="genesys"
  token: any;
  _appname:any;
  _language:any;
  _profileImage:any;
  _userData:any;
  _alreadyLogged:boolean=false;
  _productAvailable:boolean=false;
  genesysUserexists:any;
  ClientGensysUser:any
  private imageSource = new BehaviorSubject<any>('');
  currentImage = this.imageSource.asObservable();
  constructor() { }
  private imageUrlSubject = new BehaviorSubject<string>("");
  imageUrl$ = this.imageUrlSubject.asObservable();

  // Method to update the image URL
  updateImageUrl(newImageUrl: string): void {
    this.imageUrlSubject.next(newImageUrl);
  }

  get dataToken(): any{
    console.log(this.ClientGensysUser,this.genesysUserexists)
    return this.token;
  }

  @Input() set dataToken(val: any){
    this.token = val;
    console.log(this.token);
  }


 get getAppName():any {
    return this._appname;
  }

  @Input() set setAppName(appName:any) {
      this._appname=appName;
  }

  get getLanguage():any {
    return this._language;
  }

  @Input() set setLanguage(language:any) {
      this._language=language;
  }


  setUser(data) {
    this._userData=data;
}
updateImage(imageUrl: any) {
  this.imageSource.next(imageUrl);
}
// currentPlan() {
//   return this.select(user => {
//       return user.plan;
//   });
// }

// setCurrentPlan(planId) {
//   this.store.update(state => {
//       return {
//           ...state,
//           plan: planId
//       }
//   });
// }
}
